import React, { useState } from 'react';
import Modal from 'react-modal';
import { Welcome, HomeCase } from 'components';
import useContent from 'helpers/useContent';
import handIcon from '../../static/icons/hand.svg';
import alertIcon from '../../static/icons/alert.svg';

import './styles.scss';

// Componente LoginPage
const LoginPage = ({ isOpen, onClose, onLogin }) => {
  const [password, setPassword] = useState('');
  const [isPasswordEntered, setPasswordEntered] = useState(false);

  const handlePasswordChange = (event) => {
    const enteredPassword = event.target.value;
    setPassword(enteredPassword);
    setPasswordEntered(enteredPassword !== '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(password);
  };

  const handleModalClose = () => {
    setPassword('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Iniciar sesión"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-content">
        <h1><img src={handIcon} alt="Hand Icon" /> Hello!</h1>
        <h4>This content is confidential</h4>
        <p class="txt">The clients and projects featured on this website are the rightful property of their respective owners. They are presented solely to showcase the work I have accomplished for them.</p>
        <p class="txt txt2">Please enter the password you have been provided</p>
        <form onSubmit={handleSubmit}>
          <input class="input-pass" type="password" id="pass" placeholder='Password' value={password} onChange={handlePasswordChange} />
          <div class="cnt_error">
            <p class="error" id="err"><img src={alertIcon} alt="Hand Icon" /> Please enter a valid password</p>
          </div>
          <button type="submit" class="send_btn" className={`send_btn ${!isPasswordEntered ? 'disabled' : ''}`} disabled={!isPasswordEntered}>Access</button>
        </form>
      </div>
    </Modal>
  );
};

// Componente Home
const Home = () => {
  const { content } = useContent();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const handleLogin = (password) => {
    // Realiza la validación de la contraseña aquí
    if (password === '9z[4Hd4b9.bK4ram') {
      setLoggedIn(true);
      setShowModal(false);
    } else {
      const input = document.getElementById("pass");
      const err = document.getElementById("err");
      input.style.borderColor = "#CF0000";
      err.style.display = 'block';
    }
  };

  return (
    <div className="home">
      <Welcome text={content.welcome} />
      {content.cases?.map((caseContent) => (
        <HomeCase
          key={caseContent.id}
          content={caseContent}
          image={caseContent.images.home}
        />
      ))}
      <LoginPage isOpen={showModal} onClose={() => setShowModal(false)} onLogin={handleLogin} />
      {showModal && <div className="modal-overlay" />}
    </div>
  );
};

export default Home;
